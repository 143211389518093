import React from 'react';

import Layout from '@components/Layout/Layout';
import SEO from '@components/seo';

const CommentPrivacy = () => (
  <Layout>
    <SEO title="Comment Policy" />
    <h1>Comment Policy</h1>
    <p>
      <i>
        Last updated: March 09, 2023 <br></br>
      </i>
      <hr></hr>
      While we appreciate each comment, if your comment does not follow our
      comment policy, it will be removed. Continuous abuse of the policy will
      result in permanent ban.
    </p>
    <h2>FAQ</h2>
    <p>
      <b>How to get Support for a Problem?</b> - If you have a general question
      directly regarding the post or the tutorial that we posted, please add a
      comment in the post. If your question is specific regarding your site then
      you should use our Contact Form. Comment area is not the best place to ask
      a question that is not related to the post. You should ask the question
      using our Contact form. If your comment contains personal information, or
      have a personal question specific to the site, we will delete your comment
      and use your email to contact you regarding the question.
      <br></br> <br></br>
      <b>What to Do If Your Comment Does Not Appear</b> - If you leave a comment
      on this blog and it does not appear in a reasonable time period (1
      business day), and you know that it does not violate these Comment
      Policies, please contact us using the{' '}
      <a href="https://www.seelay.in/#contact">contact form</a>.
    </p>

    <h2>Policy</h2>
    <p>
      <b>Comment Name</b> - We do not allow Keywords stuffed in the name field.
      You must use your name or nick name for commenting. Comments using
      keywords such as “Web Design Company” or similar will be flagged as SPAM
      and deleted.<br></br>
      <br></br>
      <b>Valid Email</b> - Email addresses are required for commenting, and they
      are not published on the blog, nor shared. Email addresses that look like
      SPAM will be flagged as SPAM. <br></br>
      <br></br>
      <b>Language and Manners</b> - This blog is written in English, so leave
      your comments in English. SEELAY Blog is a “family friendly site”
      therefore any comment that includes offensive or inappropriate language,
      and/or considered rude by us will be edited or deleted. This is a friendly
      blog, so play it NICE. <br></br>
      <br></br>
      <b>Add Value</b> - A comment which does not add to the conversation, runs
      of on an inappropriate tangent, or kills the conversation may be edited,
      moved, or deleted. All SPAM comments will be deleted because they do not
      add value to the site. <br></br>
      <br></br>
      <b>Limit Links</b> - We love resources as they can be really helpful
      sometimes, but in order to prevent SPAM and assure quality, we must limit
      links on the comments. Do not add more than two links in the comment. If
      you do so, your comment will be added for further review. DO NOT sign your
      comments with a backlink to your site. They will be removed. <br></br>
      <br></br>
      <b>No Personal Attack Comments Permitted</b> - In the interest of fair
      play, no personal attacks are permitted in this blog's comments. You may
      question or argue the content, but not attack the blogger, nor any other
      commenters. Failure to respect fellow participants on this blog could
      result in removal and blocked access.<br></br>
      <br></br>
      <b>All Rights Reserved</b> - SEELAY reserves the right to edit, delete,
      move, or mark as spam any and all comments. We also have the right to
      block access to any one or group from commenting or from the entire blog.{' '}
      <br></br>
      <br></br>
      <b>Hold Harmless</b> - All comments within SEELAY Blog are the
      responsibility of the commenter, not of Bloger. By submitting a comment on
      our blog, you agree that the comment content is your own, and you agree to
      hold SEELAY Blog or SEELAY harmless from any and all repercussions,
      damages, or liability. If you have any questions regarding the policy, you
      may contact by using our{' '}
      <a href="https://www.seelay.in/#contact">contact form</a>.
    </p>
    <hr></hr>
  </Layout>
);

export default CommentPrivacy;
